import utils from "../../../util/utils";
const Appkey = 'FFFF0N00000000008E5C';
const Scene = 'nvc_register';
var _self;
const _module='CTRegister';
const _common="ctError";
const checkCompanyRule=(rule,value,callback)=>{
    _self.checkCompany(rule,value,callback);
}
const checkNameRule=(rule,value,callback)=>{
    _self.checkName(rule,value,callback);
}
const checkLastNameRule=(rule,value,callback)=>{
    _self.checkLastName(rule,value,callback);
}
const checkMailRule=(rule,value,callback)=>{
    _self.checkMail(rule,value,callback);
}
const checkPasswordRule=(rule,value,callback)=>{
    _self.checkPassword(rule,value,callback);
}

export default {
    name: 'register',
    components: {
    },
    data() {
        return {
            language:'en',
            registerUrl:'',
            // step 1
            title:'Create Your Account',
            companyNameLabel:"Company Name:",
            companyNamePlaceholder:"Company Name",
            nameLabel:"Name:",
            firstNamePlaceholder:"First Name",
            lastNamePlaceholder:"Last Name",
            emailLabel:"Email:",
            emailPlaceholder:"Please enter your email",
            passwordLabel:"Password:",
            passwordPlaceholder:"Please enter your password",
            checkContent:"I agree to the CIC ",
            teamscontion:'Teams & Condition',
            privacypolic:'Privacy Policy',
            buttonText:"Create Account",
            noticeText:"Always have a CIC Acount",
            loginText:"Log in",
            pleaseCheckTheAllow:"pleaseCheckTheAllow",
            AliCheckError:"AliCheckError",
            and:'and',

            // step 2
            mailtitle:'Verify your email',
            emailContent:'***@***.com',
            mailNotifyContent:"A verify mail have been send to your email.",
            anOtherMailNotifyContent:"anOtherMailNotifyContent",
            clickinfo:"Click the link inside to get start",
            mailNotArrive:"Email didn't arrive",
            gobackToEasyTrade:'Go back to CIC Easy Trade ',

            // step3
            step3EmailContent:"Verify your email",
            step3NotifyContent:"You need to confirm you email address to start using you cic account",
            step3ButtonText:"Verify Your Email",
            step3NoticeText:"If you did not sign up for this account you can ignore this email",

            // 数据验证
            companyIsNull:"公司名不能为空",
            companyIsTooLong:"公司名称长度超过100个字符",
            firstNameIsNull:"姓不能为空",
            firstNameIsTooLong:"姓超过15个字符",
            lastNameIsNull:"名不能为空",
            lastNameIsTooLong:"名超过15个字符",
            nameIsNull:"姓名不能都为空",
            mailIsNull:"邮箱不能为空",
            mailIsTooLong:"邮箱超过50个字符",
            mailIsInvalid:'邮箱格式不正确',
            passwordIsNull:'密码不能为空',
            passwordIsTooShort:'密码小于6个字符',
            passwordIsTooLong:'密码超过16个字符',
            // 数据
            allow:false,
            showCaptcha:false,
            source:0, // TODO 来源
            registerStep:1,
            ignoreSubmit:false,

            form:{
                companyName:'',
                firstName:'',
                lastName:'',
                email:'',
                password:'',
            },
            rules: {
                companyName:[{validator:checkCompanyRule,trigger:'blur'}],
                name:[{validator:checkNameRule,trigger:'blur'}],
                lastName:[{validator:checkLastNameRule,trigger:'blur'}],
                email:[{validator:checkMailRule,trigger:'blur'}],
                password:[{validator:checkPasswordRule,trigger:'blur'}]
            },

            submitDisabled:false
        }
    },
    methods:{
        gotoTeamscontion(){
            var lang = utils.getItem("languageCode");
            if(lang == "zh_cn"){
                window.open(this.$config.cicUrl+"/zh_cn/protocol?id=SEC1810170927200546");
                //this.language ="中文";
            } else {
                window.open(this.$config.cicUrl+"/en/protocol?id=SEC1810170927200546");
                //this.language ="English";
            }
        },
        gotoPolicy(){
            var lang = utils.getItem("languageCode");
            if(lang == "zh_cn"){
                window.open(this.$config.cicUrl+"/zh_cn/protocol?id=SEC1810170927547812");
                //this.language ="中文";
            } else {
                window.open(this.$config.cicUrl+"/en/protocol?id=SEC1810170927547812");
                //this.language ="English";
            }
            //window.location.href = "https://www.cic-tp.com/en/protocol?id=SEC1810170927547812"
        },
        sendAnotherMail(){
            var params = new Object();
            params.email = this.form.email;
            this.axios.post('/registerct/afreshsendemail',params).then(ret=>{
                if(ret.status === 1){
                    _self.$message.success(_self.anOtherMailNotifyContent);
                    _self.mailNotifyContent = _self.anOtherMailNotifyContent;
                } else {
                    _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                }
                if(_self.$showLog)
                    console.log(ret);
            }).catch(err=>{
                if(_self.$showLog)
                    console.log(err);
            })
        },
        checkCompany(rule,value,callback){
            if(value==''){
                callback(new Error(this.companyIsNull));

            } else if(value.length >100){
                callback(new Error(this.companyIsTooLong));
            } else {
                callback();
            }
        },
        checkName(rule,value,callback){
            if(this.form.firstName=='') {
                callback(new Error(this.firstNameIsNull));
            } else if(this.form.firstName.length >15){
                callback(new Error(this.firstNameIsTooLong));
            } else {
                callback();
            }
        },
        checkLastName(rule,value,callback){
            if(this.form.lastName == ''){
                callback(new Error(this.lastNameIsNull));
            } else if(this.form.lastName.length >15){
                callback(new Error(this.lastNameIsTooLong));
            } else {
                callback();
            }
        },
        checkMail (rule,value,callback){
            if(value==''){
                callback(new Error(this.mailIsNull));
            } else {
                if(value.length>50){
                    callback(new Error(this.mailIsTooLong));
                    return;
                }
                //let reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                // eslint-disable-next-line no-useless-escape
                // let reg=/^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/;
                if(!(value.indexOf('@')>0)){
                    callback(new Error(this.mailIsInvalid));
                }else {
                    callback();
                }
            }
        },
        checkPassword(rule,value,callback){
            if(value==''){
                callback(new Error(this.passwordIsNull));
            } else if(value.length>16){
                callback(new Error(this.passwordIsTooLong));
                return;
            } else if(value.length<6){
                callback(new Error(this.passwordIsTooShort));
                return;
            } else {
                callback();
            }
        },
        doSubmit(){
            if(this.ignoreSubmit)
                return;
            var that = this;
            if(!this.allow){
                _self.$message.error(_self.pleaseCheckTheAllow);
                return;
            }
            this.$refs.registerForm.validate(valid => {
                if (valid) {
                    that.doAliWuhenRegister();
                } else {
                    if(_self.$showLog)
                        console.log('error submit!!')
                    return false
                }
            })

        },
        doAliWuhenRegister(){
            var params = new Object();
            params.nvcval= getNVCVal();
            this.doRegister(params)
        },
        doRegister(params) {
            if(_self.$showLog)
                console.log(params);
            params.companyName = this.form.companyName;
            params.contactsFirstName = this.form.firstName;
            params.contactsLastName	= this.form.lastName;
            params.email = this.form.email;
            params.loginPassword = this.form.password;
            params.createSource	 = 0;
            params.registSource = this.source;
            _self.submitDisabled = true
            this.axios.post('/registerct/registered',params).then(ret=>{
                _self.submitDisabled = false
                if(ret.status === 1){
                    if(ret.data == 800){
                        _self.ignoreSubmit = false;
                        _self.$message.error(_self.AliCheckError);
                        getNC().then(function () {
                            _nvc_nc.reset()
                        })
                    } else if(ret.data == 400){
                        _self.ignoreSubmit = true;
                        _self.showCaptcha = true;
                        getNC().then(function () {
                            _nvc_nc.reset()
                        })
                    } else {
                        _self.ignoreSubmit = true;
                        nvcReset();
                        _self.registerStep = 2;
                        if(_self.$showLog)
                            console.log("register success");
                    }
                    //
                } else {
                    _self.ignoreSubmit = false;
                    nvcReset();
                    _self.$message.error(_self.$t(_common+'.'+ret.errorMsg));
                }
                if(_self.$showLog)
                    console.log(ret);
            }).catch(err=>{
                _self.submitDisabled = false
                if(_self.$showLog)
                    console.log(err);
            })

        },

        set_ALY_config() {
            //var self = this;

            return new Promise((resolve) => {
                var nc_token = ["CF_APP_1", (new Date()).getTime(), Math.random()].join(':');
                window.NVC_Opt = {
                    appkey: Appkey, // 开通无痕验证提供的appkey 与scene
                    scene: Scene,
                    token: nc_token,
                    isH5: false,
                    popUp: false,
                    renderTo: '#captcha',
                    nvcCallback: function(data) {
                        //debugger
                        if(_self.$showLog)
                            console.log(data);
                        var params = new Object();
                        //params.nvcval= getNVCVal();
                        params.nvcval= data;
                        _self.doRegister(params);
                    },

                    // trans: {"nvcCode":600, "key1": "code0"},
                    language: this.language,
                    //滑动验证长度配置
                    customWidth: 300,
                    customHeight: 40,
                    //刮刮卡配置项
                    width: 300,
                    height: 125,
                    elements: [
                        "//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png",
                        "//img.alicdn.com/tfs/TB17cwllsLJ8KJjy0FnXXcFDpXa-50-74.png"
                    ],
                    bg_back_prepared: "//img.alicdn.com/tps/TB1skE5SFXXXXb3XXXXXXXXXXXX-100-80.png",
                    bg_front: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABQCAMAAADY1yDdAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAADUExURefk5w+ruswAAAAfSURBVFjD7cExAQAAAMKg9U9tCU+gAAAAAAAAAIC3AR+QAAFPlUGoAAAAAElFTkSuQmCC",
                    obj_ok: "//img.alicdn.com/tfs/TB1rmyTltfJ8KJjy0FeXXXKEXXa-50-74.png",
                    bg_back_pass: "//img.alicdn.com/tfs/TB1KDxCSVXXXXasXFXXXXXXXXXX-100-80.png",
                    obj_error: "//img.alicdn.com/tfs/TB1q9yTltfJ8KJjy0FeXXXKEXXa-50-74.png",
                    bg_back_fail: "//img.alicdn.com/tfs/TB1w2oOSFXXXXb4XpXXXXXXXXXX-100-80.png",
                    upLang:{"cn":{
                            _ggk_guide: "请摁住鼠标左键，刮出两面盾牌",
                            _ggk_success: "恭喜您成功刮出盾牌<br/>继续下一步操作吧",
                            _ggk_loading: "加载中",
                            _ggk_fail: ['呀，盾牌不见了<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
                            _ggk_action_timeout: ['我等得太久啦<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
                            _ggk_net_err: ['网络实在不给力<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题'],
                            _ggk_too_fast: ['您刮得太快啦<br/>请', "javascript:noCaptcha.reset()", '再来一次', '或', "http://survey.taobao.com/survey/QgzQDdDd?token=%TOKEN", '反馈问题']
                        }
                    },

                }
                resolve()
            })
        },
        // 动态加载js文件
        get_ALY_file() {
            try {
                var stamp = new Date().getTime();
                var script = document.createElement('script');
                script.setAttribute('type', 'text/javascript');
                script.setAttribute('src', 'https://g.alicdn.com/sd/nvc/1.1.112/guide.js?t=' + stamp);
                document.documentElement.appendChild(script);
            } catch (err) {
                if(_self.$showLog)
                    console.log(err);
            }
        },
    },
    created() {
        _self = this;
        this.set_ALY_config().then(ret => {
            if(_self.$showLog)
                console.log(ret);
            this.get_ALY_file();
        });
    },
    mounted() {
        this.language = utils.getItem("languageCode") == 'zh_cn'? 'cn':'en';
        if(this.$route.query.source){
            this.source = this.$route.query.source;
        }

        this.title = this.$t(_module+'.title');
        this.companyNameLabel = this.$t(_module+'.companyNameLabel');
        this.companyNamePlaceholder = this.$t(_module+'.companyNamePlaceholder');
        this.nameLabel = this.$t(_module+'.nameLabel');
        this.firstNamePlaceholder = this.$t(_module+'.firstNamePlaceholder');
        this.lastNamePlaceholder = this.$t(_module+'.lastNamePlaceholder');
        this.emailLabel = this.$t(_module+'.emailLabel');
        this.emailPlaceholder = this.$t(_module+'.emailPlaceholder');
        this.passwordLabel = this.$t(_module+'.passwordLabel');
        this.passwordPlaceholder = this.$t(_module+'.passwordPlaceholder');
        this.checkContent = this.$t(_module+'.checkContent');
        this.teamscontion = this.$t(_module+'.teamscontion');
        this.privacypolic = this.$t(_module+'.privacypolic');
        this.and = this.$t(_module+'.and');
        this.buttonText = this.$t(_module+'.buttonText');
        this.noticeText = this.$t(_module+'.noticeText');
        this.loginText = this.$t(_module+'.loginText');
        this.pleaseCheckTheAllow = this.$t(_module+'.pleaseCheckTheAllow');
        this.AliCheckError = this.$t(_module+'.AliCheckError');

        this.mailtitle = this.$t(_module+'.mailtitle');
        this.mailNotifyContent = this.$t(_module+'.mailNotifyContent');
        this.anOtherMailNotifyContent = this.$t(_module+'.anOtherMailNotifyContent');
        this.clickinfo = this.$t(_module+'.clickinfo');
        this.mailNotArrive = this.$t(_module+'.mailNotArrive');
        this.gobackToEasyTrade = this.$t(_module+'.gobackToEasyTrade');
        this.step3EmailContent = this.$t(_module+'.step3EmailContent');
        this.step3NotifyContent = this.$t(_module+'.step3NotifyContent');
        this.step3ButtonText = this.$t(_module+'.step3ButtonText');
        this.step3NoticeText = this.$t(_module+'.step3NoticeText');
        this.companyIsNull = this.$t(_module+'.companyIsNull');
        this.companyIsTooLong = this.$t(_module+'.companyIsTooLong');
        this.firstNameIsNull = this.$t(_module+'.firstNameIsNull');
        this.firstNameIsTooLong = this.$t(_module+'.firstNameIsTooLong');

        this.lastNameIsNull = this.$t(_module+'.lastNameIsNull');
        this.lastNameIsTooLong = this.$t(_module+'.lastNameIsTooLong');
        this.nameIsNull = this.$t(_module+'.nameIsNull');
        this.mailIsNull = this.$t(_module+'.mailIsNull');
        this.mailIsTooLong = this.$t(_module+'.mailIsTooLong');
        this.mailIsInvalid = this.$t(_module+'.mailIsInvalid');
        this.passwordIsNull = this.$t(_module+'.passwordIsNull');
        this.passwordIsTooShort = this.$t(_module+'.passwordIsTooShort');
        this.passwordIsTooLong = this.$t(_module+'.passwordIsTooLong');

    }
}
