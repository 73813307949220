<template>
    <div class="register-mobile" >
        <!-- step 1-->
        <div class="createForm" v-if="registerStep==1">
            <div class="formheader">
                {{title}}
            </div>

            <el-form ref="registerForm" :model="form" :rules="rules" label-position="top">
                <el-form-item :label="companyNameLabel" prop="companyName" label-position="top">
                    <el-input  ref="companyName" v-model="form.companyName" :placeholder="companyNamePlaceholder" ></el-input>
                </el-form-item>

                <el-form-item  :label="nameLabel" prop="name">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item  prop="name">
                                <el-input ref="firstName" v-model="form.firstName" :placeholder="firstNamePlaceholder"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="lastName">
                                <el-input  ref="lastName"  v-model="form.lastName" :placeholder="lastNamePlaceholder"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form-item>

                <el-form-item :label="emailLabel" prop="email">
                    <el-input v-model="form.email" :placeholder="emailPlaceholder"></el-input>
                </el-form-item>

                <el-form-item :label="passwordLabel" prop="password">
                    <el-input  v-model="form.password" show-password :placeholder="passwordPlaceholder"></el-input>
                </el-form-item>

                <div class="captcha-box" v-if="showCaptcha">
                    <div id="captcha"></div>
                </div>

                <div v-if="language == 'en'">
                    <div class="check">
                        <el-checkbox  :label="checkContent" v-model="allow" ></el-checkbox>
                        <div class="agreeitem" @click="gotoTeamscontion()">{{teamscontion}}</div>
                        <div class="agreeitem" style="color: #003100">{{and}}</div>
                    </div>
                    <div style="margin-left: 20px;margin-top: 5px; display: flex">
                        <div class="agreeitem" @click="gotoPolicy()">{{privacypolic}}</div>
                    </div>
                </div>
                <div v-else>
                    <div class="check">
                        <el-checkbox  :label="checkContent" v-model="allow" ></el-checkbox>
                        <div class="agreeitem" @click="gotoTeamscontion()">{{teamscontion}}</div>
                        <div class="agreeitem" style="color: #003100">{{and}}</div>
                        <div class="agreeitem" @click="gotoPolicy()">{{privacypolic}}</div>
                    </div>
                </div>

                <el-button type="primary" :class="allow?'step3-button':'step3-button-disable' " @click="doSubmit()" :disabled="submitDisabled">{{buttonText}}</el-button>
                <div class="step3-bottom">
                    <div class="bottonnotice">{{noticeText}}</div>
                    <router-link to="/loginMobile" class="link" style="margin-left: 20px" replace>{{loginText}}</router-link>
                </div>
            </el-form>
        </div>
        <!-- step 2-->
        <div class="emailForm" v-if="registerStep==2">
            <div class="formheader" >
                {{mailtitle}}
            </div>
            <div class="verifyline">
                <div class="content">
                    <div class="eline">
                        <div class="emailline">{{emailLabel}}</div>
                        <div class="emailline" style="margin-left: 10px">{{form.email}}</div>
                    </div>

                    <div class="nodity">{{mailNotifyContent}}</div>
                    <div class="clickedinfo">{{clickinfo}}</div>
                </div>
            </div>
            <div class="step2-bottom">
                <router-link to="" class="link" @click.native="sendAnotherMail()">{{mailNotArrive}}</router-link>
                <router-link to="/loginMobile" class="link">{{gobackToEasyTrade}}</router-link>
            </div>
        </div>
    </div>


</template>

<script>
    import index from './index'
    export default index
</script>

<style lang="less">
    .register-mobile {
        padding: 20px;
        .createForm{
            width: 100%;
            display: flex;
            flex-direction: column;

            .account-box{
                margin-top: 0px;
                width: 100%;
                height: 88px;
            }

            .nameLabel-left {
                height: 50px;
                width: calc((100% - 20px)/2);
            }
            .nameLabel-right {
                height: 50px;
                width: calc((100% - 20px)/2);
                margin-left: 20px;
            }
            .captcha-box{
                margin-top: 30px;
                margin-bottom: 0px;
                width: 100%;
                height: 70px;
                display: flex;
            }
            .check{
                margin-top: 30px;
                display: flex;
            }

            .agreeitem{
                font-size: 14px;
                letter-spacing: 0;
                line-height: 19px;
                margin-left: 1px;
                color:#4173fb;
                cursor:pointer;
            }

            .notice{
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ACBDC7;
            }
        }

        .formheader{
            margin-top: 8px;
            color:#333333;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 30px;
            font-weight: bold;
        }
        .button{
            margin: 40px 90px 40px 90px;
            width: 420px;
            height: 70px;
            background-color: #4173fb;
            box-shadow: 0 0 10px 0 rgba(0,115,255,0.20);
            font-size: 28px;
        }
        .emailForm{
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        .emailForm .verifyline{
            height: 120px;
            display: flex;
            .content{
                color:#333333
            }
            .eline{
                display: flex;
                line-height: 22px;
                font-size: 16px;
                color:#333333
            }
            .emailline{
                font-size: 16px;
                line-height: 22px;
                color:#333333
            }
            .nodity {
                margin-top: 30px;
                font-size: 14px;
                line-height: 20px;
                color: #666666;
            }
            .clickedinfo{
                color:#BDC3D2;
                font-size: 14px;
            }

        }

        .emailForm .notice{
            width: 520px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #81899E;
        }
        .step2-bottom{
            width: 100%;
            height: 100px;
            margin-bottom: 20px;
            border-top: 1px solid #EEEEEE;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .link{
                margin-top: 30px;
                font-size: 14px;
                color: #0073FF;
                letter-spacing: 0;
                cursor: pointer;
            }
        }
        .step3-button{
            margin: 30px 0px 30px 0px;
            width: 100%;
            height: 50px;
            background-color: #0073FF;
            box-shadow: 0 0 10px 0 rgba(0,115,255,0.20);
            font-size: 18px;
            font-weight: bold;
            color: white;
        }
        .step3-button-disable{
            margin: 30px 0px 30px 0px;
            width: 100%;
            height: 50px;
            background-color: #70B0ff;
            box-shadow: 0 0 10px 0 rgba(115,150,255,0.20);
            font-size: 18px;
            font-weight: bold;
            border: 0px solid #70B0ff;
        }
        .step3-bottom{
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;

        }
        .bottonnotice{
            color: #81899E;
            font-size: 14px;
        }
        .link{
            font-size: 14px;
            color: #0073FF;
            letter-spacing: 0;
            cursor: pointer;
        }

        .createForm .el-checkbox__label{
            color: #666666;
        }
        .createForm .el-checkbox__input.is-checked+.el-checkbox__label{
            color: #666666;
        }
        .createForm .el-form-item__label {
            line-height: 20px;
        }
    }
</style>